<template>
  <div>...</div>
</template>

<script>
export default {
  components: {},
  mounted() {
    location.href = "https://rezzervasyon.com";
  },
};
</script>

<style></style>
